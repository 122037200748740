import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {notification} from "antd";
import {checkConvertedDocx} from "services/api";
import type {ViewMainProgramPageParams} from "services/navigation";

export const useCheckDocx = () => {
  const {mainProgramId} = useParams<ViewMainProgramPageParams>();
  const timerRef = useRef<NodeJS.Timer | null>(null);
  const [pdfConvertStatus, setPdfConvertStatus] = useState<"DONE" | "NEW" | "ERROR" | "PROGRESS" | "">("");

  const startTimer = () => {
    if (timerRef.current) {
      return;
    }
    timerRef.current = setInterval(async () => {
      const res = await checkConvertedDocx(mainProgramId!, false);
      setPdfConvertStatus(res);
      if (res === "DONE") {
        if (timerRef.current) {
          clearInterval(timerRef?.current);
        }
      }

      if (res === "ERROR") {
        notification.error({message: "Во время генерации документа произошла ошибка"});
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      }
    }, 1000);
  };

  const initCheck = async () => {
    const res = await checkConvertedDocx(mainProgramId!, false);
    setPdfConvertStatus(res);
    if (res !== "DONE") {
      startTimer();
    }
  };

  useEffect(() => {
    initCheck();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return {
    pdfConvertStatus
  };
};